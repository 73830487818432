// @flow
import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { media } from "@nested/brand";

const getIcon = (value: "Yes" | "No") =>
  value === "Yes" ? (
    <FontAwesomeIcon color="#3DAD90" size="lg" icon={faCheck} />
  ) : (
    <FontAwesomeIcon color="#CED9DD" size="lg" icon={faTimes} />
  );

const thStyle = css`
  font-weight: 500;
  height: 100px;
  text-align: left;
  white-space: nowrap;
  position: relative;
  ${media.tablet`
    height: auto;
    white-space: normal;
    text-align: center;
    height: 62px;
    line-height: 21px;
  `}
`;

export const Rotate = styled.div`
  transform: rotate(-45deg);
  transform-origin: 0 0;
  position: absolute;
  bottom: 15px;
  left: 0;
  ${media.tablet`
    transform: none;
    position: static;
  `}
`;

const tableWrapperStyle = css`
  ${media.desktop`
    position: relative;
    background-color: white;
    padding: 40px 0px;
  `}
`;

const topLeftStyle = css`
  display: none;
  ${media.desktop`
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  `};
`;

const topRightStyle = css`
  display: none;
  ${media.desktop`
    display: block;
    position: absolute;
    top: 0;
    right: 0;
  `};
`;

const TopLeft = () => (
  <svg width="40px" height="31px" viewBox="0 0 40 31">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M40,0 L40,0.001 L39.5506616,0 C30.0837013,0 22.233481,5.37473506 20.6696044,10.7494701 L20.3700449,10.7494701 L20.3700449,10.7494701 C14.4068295,10.7494701 9.57268818,15.5562883 9.57268818,21.4857991 C7.03425887,21.484637 4.59939705,22.4865101 2.80404322,24.2708948 C1.00868939,26.0552796 0,28.4759179 0,31 L0,0 L40,0 Z"
        fill="#FAF7F4"
      ></path>
    </g>
  </svg>
);

const TopRight = () => (
  <svg width="40px" height="31px" viewBox="0 0 40 31">
    <g
      id="top-right"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M40,0 L40,0.001 L39.5506616,0 C30.0837013,0 22.233481,5.37473506 20.6696044,10.7494701 L20.3700449,10.7494701 L20.3700449,10.7494701 C14.4068295,10.7494701 9.57268818,15.5562883 9.57268818,21.4857991 C7.03425887,21.484637 4.59939705,22.4865101 2.80404322,24.2708948 C1.00868939,26.0552796 0,28.4759179 0,31 L0,0 L40,0 Z"
        fill="#FAF7F4"
        transform="translate(20.000000, 15.500000) scale(-1, 1) translate(-20.000000, -15.500000) "
      ></path>
    </g>
  </svg>
);

export const TableWrapper = ({
  children,
  className,
}: {
  children: React$Node,
  className?: string,
}) => (
  <div css={tableWrapperStyle} className={className}>
    <div css={topLeftStyle}>
      <TopLeft />
    </div>
    <div css={topRightStyle}>
      <TopRight />
    </div>
    {children}
  </div>
);

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
  ${media.tablet`
    margin-top: 40px;
  `}
  ${media.desktop`
    width: calc(100% / 10 * 8);
    margin: auto;
  `}
`;

export const AgentTH = ({
  children,
  className,
}: {
  children: React$Node,
  className?: string,
}) => (
  <th
    css={css`
      ${thStyle}
      font-size: 16px;
    `}
    className={className}
  >
    <div
      css={css`
        position: absolute;
        bottom: 20px;
        left: 0;
      `}
    >
      {children}
    </div>
  </th>
);

export const ComparisonTH = ({
  children,
  className,
}: {
  children: React$Node,
  className?: string,
}) => (
  <th
    css={css`
      ${thStyle}
      font-size: 14px;
      width: 50px;
      ${media.tablet`
        width: 70px;
        padding: 0px 10px;
        font-size: 16px;
      `}
    `}
    className={className}
  >
    <Rotate>{children}</Rotate>
  </th>
);

export const TR = styled.tr`
  border-top: 1px solid ${({ theme }) => theme.palette.hague20};
  height: 62px;
`;

export const TD = styled.td`
  padding: 10px 0px;
  line-height: 21px;
  font-size: 16px;
  position: relative;
  text-align: left;
`;

export const IconTD = ({ value }: { value: "Yes" | "No" }) => (
  <td
    css={`
      text-align: center;
    `}
  >
    {getIcon(value)}
  </td>
);

export const FeeTD = ({ children }: { children: React$Node }) => (
  <TD
    css={css`
      font-weight: 500;
      height: 55px;
      position: relative;
      left: 10px;
      ${media.tablet`
        left: 0;
        height: auto;
        text-align: center;
      `}
    `}
  >
    <Rotate>{children}</Rotate>
  </TD>
);

export const FeesHeading = ({ children }: { children: React$Node }) => (
  <TD>
    <div
      css={`
        position: absolute;
        bottom: 35px;
        left: 0;
        ${media.tablet`
          position: static;
        `}
      `}
    >
      {children}
    </div>
  </TD>
);
