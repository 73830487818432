// @flow
import { css } from "styled-components";
import { media } from "../../media";
import {
  regularHeading,
  mediumHeading,
} from "../../components/Typography/Headings";
import { mediumParagraph } from "../../components/Typography/Paragraphs";
import {
  TableWrapper,
  Table,
  AgentTH,
  ComparisonTH,
  TR,
  TD,
  IconTD,
} from "../../components/ComparisonTable";

const backgroundStyle = css`
  background-color: white;
  padding: 40px 20px;
  ${media.desktop`
    background-color: ${({ theme }) => theme.palette.sand40};
    padding-top: 60px;
    padding-bottom: 0px;
  `}
`;

const headingWrapper = css`
  ${media.desktop`
  position: relative;
    width: calc(100% / 10 * 8);
    margin: auto;
    margin-bottom: 60px;
  `}
`;

const wrapperStyle = css`
  max-width: 1140px;
  width: 100%;
  margin: auto;
`;

const headingStyle = css`
  ${regularHeading}
  margin: 0px;
  white-space: pre-wrap;
  ${media.tablet`
    ${mediumHeading}
  `}
  ${media.desktop`
    white-space: normal;
  `}
`;

const contentStyle = css`
  width: 100%;
  max-width: 534px;
  margin: auto;
  ${media.desktop`
    max-width: none;
    width: calc(100% / 12 * 10);
  `}
`;

type Props = {
  heading: string,
  subheading: string,
  repeat: {
    feature: string,
    nested: "Yes" | "No",
    highStreet: "Yes" | "No",
    online: "Yes" | "No",
  }[],
};

export const ComparisonTable = ({ heading, subheading, repeat }: Props) => (
  <div css={backgroundStyle}>
    <div css={wrapperStyle}>
      <div css={contentStyle}>
        <div css={headingWrapper}>
          <h3 css={headingStyle}>{heading}</h3>
          <p css={mediumParagraph}>{subheading}</p>
        </div>
        <TableWrapper>
          <Table>
            <thead>
              <tr>
                <AgentTH>Agent</AgentTH>
                <ComparisonTH>Nested</ComparisonTH>
                <ComparisonTH>High Street</ComparisonTH>
                <ComparisonTH>Online</ComparisonTH>
              </tr>
            </thead>
            <tbody>
              {repeat.map(({ feature, nested, highStreet, online }) => (
                <TR>
                  <TD>{feature}</TD>
                  <IconTD value={nested} />
                  <IconTD value={highStreet} />
                  <IconTD value={online} />
                </TR>
              ))}
            </tbody>
          </Table>
        </TableWrapper>
      </div>
    </div>
  </div>
);
